<form class="col-md-12 p-0 osis" [formGroup]="widgetForm">
    <div class="form-group row">
        <mat-form-field [ngClass]="selectedtype === 'weather_forecast' ? 'col-sm-12 col-md-8 cold lg-8' : 'col-sm-4 col-md-4 col-lg-4'" required (click)="addEditTranslation('notes.name')">
            <mat-label class="col-form-label">{{"notes.name" | translate}}</mat-label>
            <input matInput formControlName="name" readonly #nameInput/>
            <mat-error *ngIf="widgetForm.controls['name'].errors?.required">
                <strong>{{
                    "auth.validation.requiredField" | translate
                }}</strong>
            </mat-error>
        </mat-form-field>
        
        <mat-form-field class="col-sm-4 col-md-4 lg-4" [ngClass]="selectedtype === 'weather_forecast' ? 'hide' : 'show'">
            <mat-label>{{ "notes.lot" | translate }}</mat-label>
            <mat-select
                formControlName="lot"
                name="lot"
                (ngModelChange)="filterSensors($event); filterCoordSensors($event); filterPercentageSensors($event)"
            >
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput #lotFilter placeholder="Search" />
                </mat-form-field>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option
                        *ngFor="let lot of lots | filter: lotFilter.value"
                        [value]="lot?.id"
                    >
                        {{ lot.name }}
                    </mat-option>
                </div>
            </mat-select>
            <mat-error *ngIf="widgetForm.controls['lot'].errors?.required">
                <strong>{{
                    "auth.validation.requiredField" | translate
                }}</strong>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-sm-4 col-md-4 lg-4">
            <mat-label>{{"widget.order" | translate}}</mat-label>
            <input matInput [placeholder]="'widget.order' | translate" type="number" formControlName="order" (blur)="changeOrder($event.target.value)">
            <!-- <mat-select
                formControlName="order"
                (ngModelChange)="changeOrder($event)"
                required
            >
                <div class="scroll_wrap">
                    <mat-option *ngFor="let i of getNumberArray(widgetsLength)" [value]="i">{{ i }}</mat-option>
                </div>
            </mat-select> -->

        </mat-form-field>
        <div class="col-12" [ngClass]="selectedtype === 'weather_forecast' ? 'show' : 'hide'">
            <chips 
                [items]="lots" 
                [selectedItems]="selectedItems"
                [label]="'activities.lots' | translate"
                (updatedItems)="updateItems($event)"
                [addEnabled]="false"
            >
            </chips>
        </div>
    </div>
    <div class="form-group row">
        <mat-form-field [ngClass]="selectedtype === 'weather_forecast' ? 'col-sm-12 col-md-12 cold lg-12' : 'col-sm-6 col-md-6 col-lg-6'">
            <mat-label>{{ "widget.widgetType" | translate }}</mat-label>
            <mat-select
                required
                formControlName="type"
                (ngModelChange)="changedtype($event)"
            >
                <mat-form-field class="w-100" appearance="fill">
                    <!-- <mat-label>
            <em class="fa fa-search f-20"></em>
          </mat-label> -->
                    <input matInput #typeFilter placeholder="Search" />
                </mat-form-field>
                <div class="scroll_wrap">
                    <mat-option style="height: 0!important;"></mat-option>
                    <mat-option
                        *ngFor="
                            let type of widgetTypes
                                | filter: typeFilter.value
                        "
                        [value]="type.uniqueid"
                    >
                    {{type?.nameT ? getTranslation(type?.nameT) : type?.name}}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>

        <!-- <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="selectedtype === 'devices'">
            <label class="col-form-label float">View</label>
            <div class="fake_input" (click)="openConfigModal()">
                 <ng-container *ngFor="let type of sensorGroupTypes">
                    <ng-container *ngIf="sensorGroupview === type?.id">
                        <span>{{type?.name}}</span>
                    </ng-container>
                 </ng-container>
            </div>
        </div> -->

        <mat-form-field
                class="col-md-6"
                *ngIf="selectedtype === 'graph_percentage'"
            >
                <mat-label>{{ "widget.graphType" | translate }}</mat-label>
                <mat-select required formControlName="graphType">
                    <mat-form-field class="w-100" appearance="fill">
                        <input matInput #graphTypeFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_bar">
                        <mat-option
                            *ngFor="
                                let graphType of widgetServices.percentageGraphTypes
                                    | filter: graphTypeFilter.value
                            "
                            [value]="graphType.id"
                        >
                            {{ graphType?.name | translate }}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>

        <ng-container *ngIf="selectedtype === 'pool'">
            <div class="col-sm-6 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
                <button class="btn save" (click)="editWigetValue()">{{'widget.editData' | translate}}</button>
            </div>
        </ng-container>

        <ng-container *ngIf="selectedtype === 'map'">
            <div class="col-sm-6 col-md-6 col-lg-6" style="text-transform: capitalize;">
                <mat-form-field class="w-100">
                    <mat-label>{{ "widget.mapType" | translate }}</mat-label>
                    <mat-select
                        formControlName="mapType"
                        name="lot"
                        (ngModelChange)="changeType($event)"
                    >
                        <div class="scroll_wrap">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-option
                                *ngFor="let type of mapTypes"
                                [value]="type"
                            >
                               <span style="text-transform: capitalize;">{{ type }}</span>
                            </mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="widgetForm.controls['mapType'].errors?.required">
                        <strong>{{
                            "auth.validation.requiredField" | translate
                        }}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
    </div>
    <div class="col-md-12 rowscols" style="padding: 0">
        <mat-form-field class="col-sm-3 col-md-3 lg-3" style="padding-left: 0">
            <mat-label>{{"widget.phoneSize" | translate}}</mat-label>
            <mat-select
                formControlName="sizeSmall"
                (ngModelChange)="changesizeSmalls($event)"
                required
            >
                <div class="scroll_wrap">
                    <mat-option
                        *ngFor="
                            let t of [
                                11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24,
                                25, 26, 27, 28
                            ]
                        "
                        [value]="'s' + t"
                    >
                        <span *ngIf="t == 11">1 x 1</span>
                        <span *ngIf="t == 12">1 x 2</span>
                        <span *ngIf="t == 13">1 x 3</span>
                        <span *ngIf="t == 14">1 x 4</span>
                        <span *ngIf="t == 15">1 x 5</span>
                        <span *ngIf="t == 16">1 x 6</span>
                        <span *ngIf="t == 17">1 x 7</span>
                        <span *ngIf="t == 18">1 x 8</span>
                        <span *ngIf="t == 21">2 x 1</span>
                        <span *ngIf="t == 22">2 x 2</span>
                        <span *ngIf="t == 23">2 x 3</span>
                        <span *ngIf="t == 24">2 x 4</span>
                        <span *ngIf="t == 25">2 x 5</span>
                        <span *ngIf="t == 26">2 x 6</span>
                        <span *ngIf="t == 27">2 x 7</span>
                        <span *ngIf="t == 28">2 x 8</span>
                        <span style="font-size: 9px"> {{"widget.colRow" | translate}}</span>
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-sm-3 col-md-3 lg-3" style="padding-right: 0">
            <mat-label>{{"widget.tabSize" | translate}}</mat-label>
            <mat-select
                formControlName="sizeMedium"
                (ngModelChange)="changesizeMediums($event)"
                required
            >
                <div class="scroll_wrap">
                    <mat-option
                        *ngFor="
                            let t of [
                                11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24,
                                25, 26, 27, 28, 31, 32, 33, 34, 35, 36, 37, 38,
                                41, 42, 43, 44, 45, 46, 47, 48
                            ]
                        "
                        [value]="'m' + t"
                    >
                        <span *ngIf="t == 11">1 x 1</span>
                        <span *ngIf="t == 12">1 x 2</span>
                        <span *ngIf="t == 13">1 x 3</span>
                        <span *ngIf="t == 14">1 x 4</span>
                        <span *ngIf="t == 15">1 x 5</span>
                        <span *ngIf="t == 16">1 x 6</span>
                        <span *ngIf="t == 17">1 x 7</span>
                        <span *ngIf="t == 18">1 x 8</span>
                        <span *ngIf="t == 21">2 x 1</span>
                        <span *ngIf="t == 22">2 x 2</span>
                        <span *ngIf="t == 23">2 x 3</span>
                        <span *ngIf="t == 24">2 x 4</span>
                        <span *ngIf="t == 25">2 x 5</span>
                        <span *ngIf="t == 26">2 x 6</span>
                        <span *ngIf="t == 27">2 x 7</span>
                        <span *ngIf="t == 28">2 x 8</span>
                        <span *ngIf="t == 31">3 x 1</span>
                        <span *ngIf="t == 32">3 x 2</span>
                        <span *ngIf="t == 33">3 x 3</span>
                        <span *ngIf="t == 34">3 x 4</span>
                        <span *ngIf="t == 35">3 x 5</span>
                        <span *ngIf="t == 36">3 x 6</span>
                        <span *ngIf="t == 37">3 x 7</span>
                        <span *ngIf="t == 38">3 x 8</span>
                        <span *ngIf="t == 41">4 x 1</span>
                        <span *ngIf="t == 42">4 x 2</span>
                        <span *ngIf="t == 43">4 x 3</span>
                        <span *ngIf="t == 44">4 x 4</span>
                        <span *ngIf="t == 45">4 x 5</span>
                        <span *ngIf="t == 46">4 x 6</span>
                        <span *ngIf="t == 47">4 x 7</span>
                        <span *ngIf="t == 48">4 x 8</span>
                        <span style="font-size: 9px"> {{"widget.colRow" | translate}}</span>
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-sm-3 col-md-3 lg-3" style="padding-right: 0">
            <mat-label>{{"widget.pcSize" | translate}}</mat-label>
            <mat-select
                formControlName="sizeLarge"
                (ngModelChange)="changesizeLarges($event)"
                required
            >
                <div class="scroll_wrap">
                    <mat-option
                        *ngFor="
                            let t of [
                                11, 12, 13, 14, 15, 16, 17, 18,
                                21, 22, 23, 24, 25, 26, 27, 28, 
                                31, 32, 33, 34, 35, 36, 37, 38,
                                41, 42, 43, 44, 45, 46, 47, 48, 
                                51, 52, 53, 54, 55, 56, 57, 58, 
                                61, 62, 63, 64, 65, 66, 67, 68,
                                71, 72, 73, 74, 75, 76, 77, 78,
                                81, 82, 83, 84, 85, 86, 87, 88,
                                91, 92, 93, 94, 95, 96, 97, 98,
                                101, 102, 103, 104, 105, 106, 107, 108,
                                111, 112, 113, 114, 115, 116, 117, 118,
                                121, 122, 123, 124, 125, 126, 127, 128
                            ]
                        "
                        [value]="'l' + t"
                    >
                        <span *ngIf="t == 11">1 x 1</span>
                        <span *ngIf="t == 12">1 x 2</span>
                        <span *ngIf="t == 13">1 x 3</span>
                        <span *ngIf="t == 14">1 x 4</span>
                        <span *ngIf="t == 15">1 x 5</span>
                        <span *ngIf="t == 16">1 x 6</span>
                        <span *ngIf="t == 17">1 x 7</span>
                        <span *ngIf="t == 18">1 x 8</span>
                        <span *ngIf="t == 21">2 x 1</span>
                        <span *ngIf="t == 22">2 x 2</span>
                        <span *ngIf="t == 23">2 x 3</span>
                        <span *ngIf="t == 24">2 x 4</span>
                        <span *ngIf="t == 25">2 x 5</span>
                        <span *ngIf="t == 26">2 x 6</span>
                        <span *ngIf="t == 27">2 x 7</span>
                        <span *ngIf="t == 28">2 x 8</span>
                        <span *ngIf="t == 31">3 x 1</span>
                        <span *ngIf="t == 32">3 x 2</span>
                        <span *ngIf="t == 33">3 x 3</span>
                        <span *ngIf="t == 34">3 x 4</span>
                        <span *ngIf="t == 35">3 x 5</span>
                        <span *ngIf="t == 36">3 x 6</span>
                        <span *ngIf="t == 37">3 x 7</span>
                        <span *ngIf="t == 38">3 x 8</span>
                        <span *ngIf="t == 41">4 x 1</span>
                        <span *ngIf="t == 42">4 x 2</span>
                        <span *ngIf="t == 43">4 x 3</span>
                        <span *ngIf="t == 44">4 x 4</span>
                        <span *ngIf="t == 45">4 x 5</span>
                        <span *ngIf="t == 46">4 x 6</span>
                        <span *ngIf="t == 47">4 x 7</span>
                        <span *ngIf="t == 48">4 x 8</span>
                        <span *ngIf="t == 51">5 x 1</span>
                        <span *ngIf="t == 52">5 x 2</span>
                        <span *ngIf="t == 53">5 x 3</span>
                        <span *ngIf="t == 54">5 x 4</span>
                        <span *ngIf="t == 55">5 x 5</span>
                        <span *ngIf="t == 56">5 x 6</span>
                        <span *ngIf="t == 57">5 x 7</span>
                        <span *ngIf="t == 58">5 x 8</span>
                        <span *ngIf="t == 61">6 x 1</span>
                        <span *ngIf="t == 62">6 x 2</span>
                        <span *ngIf="t == 63">6 x 3</span>
                        <span *ngIf="t == 64">6 x 4</span>
                        <span *ngIf="t == 65">6 x 5</span>
                        <span *ngIf="t == 66">6 x 6</span>
                        <span *ngIf="t == 67">6 x 7</span>
                        <span *ngIf="t == 68">6 x 8</span>
                        <span *ngIf="t == 71">7 x 1</span>
                        <span *ngIf="t == 72">7 x 2</span>
                        <span *ngIf="t == 73">7 x 3</span>
                        <span *ngIf="t == 74">7 x 4</span>
                        <span *ngIf="t == 75">7 x 5</span>
                        <span *ngIf="t == 76">7 x 6</span>
                        <span *ngIf="t == 77">7 x 7</span>
                        <span *ngIf="t == 78">7 x 8</span>
                        <span *ngIf="t == 81">8 x 1</span>
                        <span *ngIf="t == 82">8 x 2</span>
                        <span *ngIf="t == 83">8 x 3</span>
                        <span *ngIf="t == 84">8 x 4</span>
                        <span *ngIf="t == 85">8 x 5</span>
                        <span *ngIf="t == 86">8 x 6</span>
                        <span *ngIf="t == 87">8 x 7</span>
                        <span *ngIf="t == 88">8 x 8</span>
                        <span *ngIf="t == 91">9 x 1</span>
                        <span *ngIf="t == 92">9 x 2</span>
                        <span *ngIf="t == 93">9 x 3</span>
                        <span *ngIf="t == 94">9 x 4</span>
                        <span *ngIf="t == 95">9 x 5</span>
                        <span *ngIf="t == 96">9 x 6</span>
                        <span *ngIf="t == 97">9 x 7</span>
                        <span *ngIf="t == 98">9 x 8</span>
                        <span *ngIf="t == 101">10 x 1</span>
                        <span *ngIf="t == 102">10 x 2</span>
                        <span *ngIf="t == 103">10 x 3</span>
                        <span *ngIf="t == 104">10 x 4</span>
                        <span *ngIf="t == 105">10 x 5</span>
                        <span *ngIf="t == 106">10 x 6</span>
                        <span *ngIf="t == 107">10 x 7</span>
                        <span *ngIf="t == 108">10 x 8</span>
                        <span *ngIf="t == 111">11 x 1</span>
                        <span *ngIf="t == 112">11 x 2</span>
                        <span *ngIf="t == 113">11 x 3</span>
                        <span *ngIf="t == 114">11 x 4</span>
                        <span *ngIf="t == 115">11 x 5</span>
                        <span *ngIf="t == 116">11 x 6</span>
                        <span *ngIf="t == 117">11 x 7</span>
                        <span *ngIf="t == 118">11 x 8</span>
                        <span *ngIf="t == 121">12 x 1</span>
                        <span *ngIf="t == 122">12 x 2</span>
                        <span *ngIf="t == 123">12 x 3</span>
                        <span *ngIf="t == 124">12 x 4</span>
                        <span *ngIf="t == 125">12 x 5</span>
                        <span *ngIf="t == 126">12 x 6</span>
                        <span *ngIf="t == 127">12 x 7</span>
                        <span *ngIf="t == 128">12 x 8</span>
                        
                        <span style="font-size: 9px"> {{"widget.colRow" | translate}}</span>
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-sm-3 col-md-3 lg-3" style="padding-right: 0">
            <mat-label>{{"widget.bg" | translate}}</mat-label>
            <mat-select
                formControlName="bgColor"
                (ngModelChange)="changeBgColor($event)"
                required
            >
                <div class="scroll_wrap">
                    <mat-option *ngFor="let t of colors" [value]="'bg_' + t">
                        <span *ngIf="t === 'white'">{{"widget.white" | translate}}</span>
                        <span *ngIf="t === 'gray'">{{"widget.gray" | translate}}</span>
                        <span *ngIf="t === 'pink'">{{"widget.pink" | translate}}</span>
                        <span *ngIf="t === 'blue'">{{"widget.blue" | translate}}</span>
                        <span *ngIf="t === 'orange'">{{"widget.orange" | translate}}</span>
                        <span *ngIf="t === 'green'">{{"widget.green" | translate}}</span>
                        <span *ngIf="t === 'brown'">{{"widget.brown" | translate}}</span>
                        <span *ngIf="t === 'yellow'">{{"widget.yellow" | translate}}</span>
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
   
    <div 
    class="form-group row mt-3"
    *ngIf="selectedtype === 'fungal_alert' || selectedtype === 'pathogen_alert' || selectedtype === 'pest_alert'"
>
    <mat-form-field class="col-md-12">
        <mat-label>{{"widget.disease" | translate}}</mat-label>
        <mat-select
        formControlName="disease"
        [alwaysCallback]="true" panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()"
        (ngModelChange)="changeDisease($event)"
        (infiniteScroll)="scrolledDown()" [complete]="offset === filteredDiseases.length" msInfiniteScroll
        required>
        <div class="d-flex" style="align-items: center; position: relative;">
        <mat-form-field appearance="fill" class="w-100">
            <input matInput #diseaseFilter placeholder="Search" (keydown.enter)="filterProduct(diseaseFilter.value); $event.stopPropagation()"/>
        </mat-form-field>
        <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterProduct(diseaseFilter.value)">
            <i class="fa fa-search f-20"></i>
        </mat-label>
        </div>
        <div class="scroll_wrap" (scroll)="onScroll($event)">
            <mat-option style="height: 0!important;"></mat-option>
            <mat-option
                *ngFor="let disease of filteredDiseases"
                [value]="disease.id"
            >
            <span *ngIf="disease?.nameT">
                <span *ngIf="getTranslation(disease?.nameT) === '' || getTranslation(disease?.nameT) == null || getTranslation(disease?.nameT) == undefined ">{{disease?.name}}</span>
                <span *ngIf="getTranslation(disease?.nameT) !== ''">{{getTranslation(disease?.nameT)}}</span>
            </span>
            <span *ngIf="!disease?.nameT">{{disease?.name}} </span> <i *ngIf="disease?.latin">({{disease?.latin}})</i>
            </mat-option>
        </div>
    </mat-select>
    </mat-form-field>
    </div>

    <div 
    class="form-group row mt-3"
    *ngIf="selectedtype === 'herbicide'"
>
<mat-form-field class="col-md-12">
        <mat-label>{{"widget.activity" | translate}}</mat-label>
        <mat-select
        formControlName="activity"
        (ngModelChange)="changeActivity($event)"
        [alwaysCallback]="true" panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()"
        (infiniteScroll)="scrolledActivities()" [complete]="offset === activities.length" msInfiniteScroll
        required>
        <div class="d-flex" style="align-items: center;">
            <mat-form-field appearance="fill" class="w-100">
                <input matInput #activityFilter placeholder="Search" (keydown.enter)="filterActivity(activityFilter.value); $event.stopPropagation()"/>
            </mat-form-field>
            <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterActivity(activityFilter.value)">
                <i class="fa fa-search f-20"></i>
            </mat-label>
        </div>
        <div class="scroll_wrap" (scroll)="onScroll($event)">
            <mat-option style="height: 0!important;"></mat-option>
            <mat-option
                *ngFor="let activity of activities"
                [value]="activity.id"
            >
            {{activity?.name}}
            </mat-option>
        </div>
    </mat-select>
    </mat-form-field>
    </div>

    <ng-container
        *ngIf="
            selectedtype === 'graph' ||
            selectedtype === 'table' ||
            selectedtype === 'image' ||
            selectedtype === 'sensor_value' ||
            selectedtype === 'overview' ||
            selectedtype === 'fungal_alert' ||
            selectedtype === 'pest_alert' ||
            selectedtype === 'pathogen_alert' ||
            selectedtype === 'herbicide' ||
            selectedtype === 'images_list' ||
            selectedtype === 'map' ||
            selectedtype === 'graph_range' ||
            selectedtype === 'graph_percentage' ||
            selectedtype === 'sensor_formdata'
        "
    >
    <div class="form-group"
    *ngIf="selectedtype !== 'fungal_alert' && selectedtype !== 'pest_alert' && selectedtype !== 'pathogen_alert' && selectedtype !== 'herbicide' && selectedtype !== 'bins' && selectedtype !== 'weather_forecast'">
    <mat-list cdkDropList (cdkDropListDropped)="dropItem($event)">
        <ng-container *ngFor="let item of itemList; let idx = index">
            <!-- Content for non-mobile screens with drag-and-drop -->
            <mat-list-item
                style="text-transform: capitalize"
                *ngIf="!isMobileScreen; else mobileContent"
                cdkDrag
                [cdkDragData]="item">
                <span class="small_text il">{{ item?.sensor?.id }}.</span>
                <span class="middle_text">
                    <ng-container *ngFor="let object of objects">
                        <span *ngIf="item?.sensor?.sensorRef?.object_id == object?.id">{{ object?.extraText }}</span>
                    </ng-container>
                    <ng-container *ngFor="let unitType of unitTypes">
                        <span *ngIf="item?.sensor?.sensorRef?.unit_type == unitType?.id">{{ unitType?.name }}</span>
                    </ng-container>
                    <span class="small_text" style="display: block; text-align: left;">{{ item?.sensor?.device }}</span>
                </span>
                <span class="mr-1 ml-auto justify-content-end" style="min-width: 58px; text-align: right; display: flex;">
                    <span class="mr-1 ml-auto btn config_btn" *ngIf="selectedtype === 'sensor_value' || selectedtype === 'image' || selectedtype === 'images_list'" (click)="editCss(item)">
                        {{ 'widget.config' | translate }}
                    </span>
                    <p (click)="editGraphStyle(item)" [style.color]="item.color" *ngIf="selectedtype === 'graph'" style="vertical-align: middle; margin-bottom: 0px; margin-right: 8px; display: flex; align-items: center; justify-content: center;">
                        <span class="clr" [ngStyle]="{'background': item?.color}" style="height: 15px; width: 15px; display: inline-block; margin-right: 10px;"></span>
                        <img style="max-width: 15px" [src]="'../../../../../assets/icons/' + item.graphType + '.svg'" alt="">
                    </p>
                    <em class="material-icons pt-1 theme-color" (click)="removeItem(idx)">delete</em>
                </span>
            </mat-list-item>
            <!-- Content for mobile screens without drag-and-drop -->
            <ng-template #mobileContent>
                <mat-list-item>
                    <span class="small_text il">{{ item?.sensor?.id }}.</span>
                    <span class="middle_text">
                        <ng-container *ngFor="let object of objects">
                            <span *ngIf="item?.sensor?.sensorRef?.object_id == object?.id">{{ object?.extraText }}</span>
                            <span *ngIf="item?.sensor?.object_id == object?.id">{{ object?.extraText }}</span>
                        </ng-container>
                        <ng-container *ngFor="let unitType of unitTypes">
                            <span *ngIf="item?.sensor?.sensorRef?.unit_type == unitType?.id">{{ unitType?.name }}</span>
                        </ng-container>
                        <span class="small_text" style="display: block; text-align: left;">{{ item?.sensor?.device }}</span>
                    </span>
                    <span class="mr-1 ml-auto justify-content-end" style="min-width: 58px; text-align: right; display: flex;">
                        <span class="mr-1 ml-auto btn config_btn" *ngIf="selectedtype === 'sensor_value' || selectedtype === 'image' || selectedtype === 'images_list'" (click)="editCss(item)">
                            {{ 'widget.config' | translate }}
                        </span>
                        <p (click)="editGraphStyle(item)" [style.color]="item.color" *ngIf="selectedtype === 'graph'" style="vertical-align: middle; margin-bottom: 0px; margin-right: 8px; display: flex; align-items: center; justify-content: center;">
                            <span class="clr" [ngStyle]="{'background': item?.color}" style="height: 15px; width: 15px; display: inline-block; margin-right: 10px;"></span>
                            <img style="max-width: 15px" [src]="'../../../../../assets/icons/' + item.graphType + '.svg'" alt="">
                        </p>
                        <em class="material-icons pt-1 theme-color" (click)="removeItem(idx)">delete</em>
                    </span>
                </mat-list-item>
            </ng-template>
        </ng-container>
    </mat-list>
    </div>

        <form
            class="form-group row bgc"
            [ngClass]="itemList && itemList.length < 100 ? 'show' : 'hide'"
            [formGroup]="sensorForm"
            *ngIf="selectedtype === 'overview'"
        >
            <mat-form-field
            class="col-md-5"
            >
                <mat-label>{{ "widget.selectSensor" | translate }}</mat-label>

                <mat-select #matSelect required formControlName="sensorId" (selectionChange)="onOptionSelected($event, 100)">
                    <mat-form-field [floatLabel]="'never'" appearance="fill" class="w-100 sen_list">
                      <input matInput #farmFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                      <mat-optgroup
                        *ngFor="let group of sensorGroup | filter: farmFilter.value"
                      >
                      <mat-checkbox
                        #selectAllCheckbox
                        (click)="$event.stopPropagation()"
                        (change)="selectAllChildren($event, group)"
                        [checked]="areAllChildrenSelected(group)"
                        >
                        {{ group?.device?.nameT ? getTranslation(group?.device?.nameT) : (group?.device.name || '') + ' ' + '(' +group?.device?.id + ')' }}
                        </mat-checkbox>
                    <div class="slct">
                      <mat-option
                        *ngFor="let sensor of group.data | filter: farmFilter.value"
                        [value]="sensor.id"
                        style="text-transform: capitalize"
                        (click)="$event.stopPropagation(); $event.preventDefault()"
                      >
                            <span class="small_text">{{ sensor.id }}.</span>
                            <span class="middle_text">
                              {{ sensor?.objects[0]?.extraText }} {{ sensor?.sensorRef?.unit_types[0]?.name }} <div class="sensor_unit" *ngIf="sensor?.sensorRef?.unit_types[0]?.unit != ''">({{ sensor?.sensorRef?.unit_types[0]?.unit }})</div>
                              <!-- <span class="small_text" style="display: block;">{{sensor?.device}}</span> -->
                            </span>
                          </mat-option>
                        </div>
                      </mat-optgroup>
                    </div>
                  </mat-select>
                  
            </mat-form-field>

        </form>
        <form
            class="form-group row bgc"
            [formGroup]="sensorForm"
            *ngIf="itemList && itemList.length < 100 && selectedtype === 'graph_percentage'"
        >
            <mat-form-field
                class="col-md-5"
            >
                <mat-label>{{ "widget.selectSensor" | translate }}</mat-label>

                <mat-select required formControlName="sensorId" (selectionChange)="onOptionSelected($event, 1)">
                    <mat-form-field
                        [floatLabel]="'never'"
                        appearance="fill"
                        class="w-100 sen_list"
                    >
                        <input matInput #farmFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-optgroup
                            *ngFor="
                                let group of percentageSensorGroup
                                    | filter: farmFilter.value
                            "
                            [label]="group?.device?.nameT ? getTranslation(group?.device?.nameT) : (group?.device.name || '') + ' ' + '(' +group?.device?.id + ')'"

                        >
                            <div class="slct">
                                <mat-option
                                    *ngFor="
                                        let sensor of group.data
                                            | filter: farmFilter.value
                                    "
                                    [value]="sensor.id"
                                    style="text-transform: capitalize"
                                >
                                    <span class="small_text"
                                        >{{ sensor?.id }}.</span
                                    >
                                    <span
                                        class="middle_text"
                                        >{{ sensor?.objects[0]?.extraText }}
                                        {{ sensor?.sensorRef?.unit_types[0]?.name }} 
                                        <div class="sensor_unit" *ngIf="sensor?.sensorRef?.unit_types[0]?.unit != ''">({{ sensor?.sensorRef?.unit_types[0]?.unit }})</div>
                                        <!-- <span class="small_text" style="display: block;">{{ sensor?.device }}</span> -->
                                        </span
                                    >
                                </mat-option>
                            </div>
                        </mat-optgroup>
                    </div>
                </mat-select>
            </mat-form-field>
        </form>
        <form
            class="form-group row bgc"
            [ngClass]="itemList && itemList.length < 1 ? 'show' : 'hide'"
            [formGroup]="sensorForm"
            *ngIf="selectedtype === 'map'"
        >
            <mat-form-field
            class="col-md-5"
            >
                <mat-label>{{ "widget.selectSensor" | translate }}</mat-label>

                <mat-select required formControlName="sensorId" (selectionChange)="onOptionSelected($event, 6)">
                    <mat-form-field
                        [floatLabel]="'never'"
                        appearance="fill"
                        class="w-100 sen_list"
                    >
                        <input matInput #farmFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-optgroup
                            *ngFor="
                                let group of coordSensorGroup
                                    | filter: farmFilter.value
                            "
                            [label]="group?.device?.nameT ? getTranslation(group?.device?.nameT) : (group?.device.name || '') + ' ' + '(' +group?.device?.id + ')'"

                        >
                            <div class="slct">
                                <mat-option
                                    *ngFor="
                                        let sensor of group.data
                                            | filter: farmFilter.value
                                    "
                                    [value]="sensor.id"
                                    style="text-transform: capitalize"
                                >
                                    <span class="small_text"
                                        >{{ sensor?.id }}.</span
                                    >
                                    <span
                                        class="middle_text"
                                        >{{ sensor?.objects[0]?.extraText }} 
                                        {{ sensor?.sensorRef?.unit_types[0]?.name }} 
                                        <div class="sensor_unit" *ngIf="sensor?.sensorRef?.unit_types[0]?.unit != ''">({{ sensor?.sensorRef?.unit_types[0]?.unit }})</div>
                                        <!-- <span class="small_text" style="display: block;">{{ sensor?.device }}</span> -->
                                        </span
                                    >
                                </mat-option>
                            </div>
                        </mat-optgroup>
                    </div>
                </mat-select>
                  
            </mat-form-field>

        </form>
        <form
            class="form-group row bgc"
            [formGroup]="sensorForm"
            *ngIf="itemList && itemList.length < 1 && (selectedtype === 'cropeye' ||
            selectedtype === 'images_list' || selectedtype === 'image' || selectedtype === 'cropeye2' || selectedtype === 'sensor_formdata') "
        >
            <mat-form-field
                class="col-md-5"
            >
                <mat-label>{{ "widget.selectSensor" | translate }}</mat-label>

                <mat-select required formControlName="sensorId" (selectionChange)="onOptionSelected($event, 1)">
                    <mat-form-field
                        [floatLabel]="'never'"
                        appearance="fill"
                        class="w-100 sen_list"
                    >
                        <input matInput #farmFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-optgroup
                            *ngFor="
                                let group of sensorGroup
                                    | filter: farmFilter.value
                            "
                            [label]="group?.device?.nameT ? getTranslation(group?.device?.nameT) : (group?.device.name || '') + ' ' + '(' +group?.device?.id + ')'"

                        >
                            <div class="slct">
                                <mat-option
                                    *ngFor="
                                        let sensor of group.data
                                            | filter: farmFilter.value
                                    "
                                    [value]="sensor.id"
                                    style="text-transform: capitalize"
                                >
                                    <span class="small_text"
                                        >{{ sensor?.id }}.</span
                                    >
                                    <span
                                        class="middle_text"
                                        >{{ sensor?.objects[0]?.extraText }} 
                                        {{ sensor?.sensorRef?.unit_types[0]?.name }} <div class="sensor_unit" *ngIf="sensor?.sensorRef?.unit_types[0]?.unit != ''">({{ sensor?.sensorRef?.unit_types[0]?.unit }})</div>
                                        <!-- <span class="small_text" style="display: block;">{{ sensor?.device }}</span> -->
                                        </span
                                    >
                                    <!-- <span class="small_text">{{
                                        sensor?.device
                                    }}</span> -->
                                </mat-option>
                            </div>
                        </mat-optgroup>
                    </div>
                </mat-select>
            </mat-form-field>
            <!-- <mat-form-field
                class="col-md-3"
                *ngIf="selectedtype === 'graph'"
            >
                <mat-label>{{ "widget.graphType" | translate }}</mat-label>
                <mat-select required formControlName="graphType">
                    <mat-form-field class="w-100" appearance="fill">
                        <input matInput #graphTypeFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_bar">
                        <mat-option
                            *ngFor="
                                let graphType of widgetServices.graphTypes
                                    | filter: graphTypeFilter.value
                            "
                            [value]="graphType.id"
                        >
                            {{ graphType?.name | translate }}
                        </mat-option>
                    </div>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="col-md-3"
                *ngIf="selectedtype === 'graph'"
            >
                <mat-label>{{ "Color" }}</mat-label>
                <input
                    matInput
                    [(colorPicker)]="color"
                    [value]="color"
                    [style.color]="color"
                />
            </mat-form-field> -->

            <!--<em
                class="fa fa-plus f-20 ter-btn-icon ter-btn-icon-success"
                (click)="addItem()"
            ></em>-->
        </form>
        <form
            class="form-group row bgc"
            [formGroup]="sensorForm"
            *ngIf="
                itemList && itemList.length < (selectedtype === 'table' ? 12 : 6) &&
                
                selectedtype !== 'fungal_alert' &&
                selectedtype !== 'pathogen_alert' &&
                selectedtype !== 'herbicide' &&
                selectedtype !== 'pest_alert' &&
                selectedtype !== 'overview' &&
                selectedtype !== 'images_list' &&
                selectedtype !== 'image' &&
                selectedtype !== 'weather_forecast' &&
                selectedtype !== 'sensor_value' &&
                selectedtype !== 'map' &&
                selectedtype !== 'graph_percentage' &&
                selectedtype !== 'sensor_formdata'
            "
        >
            <mat-form-field
                class="col-md-5"
            >
                <mat-label>{{ "widget.selectSensor" | translate }}</mat-label>

                <mat-select required formControlName="sensorId" (selectionChange)="onOptionSelected($event, (selectedtype === 'table' ? 12 : 6))">
                    <mat-form-field
                        [floatLabel]="'never'"
                        appearance="fill"
                        class="w-100 sen_list"
                    >
                        <input matInput #farmFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-optgroup
                            *ngFor="
                                let group of sensorGroup
                                    | filter: farmFilter.value
                            "
                            [label]="group?.device?.nameT ? getTranslation(group?.device?.nameT) : (group?.device.name || '') + ' ' + '(' +group?.device?.id + ')'"

                        >
                            <div class="slct">
                                <mat-option
                                    *ngFor="
                                        let sensor of group.data
                                            | filter: farmFilter.value
                                    "
                                    [value]="sensor.id"
                                    style="text-transform: capitalize"
                                >
                                    <span class="small_text"
                                        >{{ sensor?.id }}.</span
                                    >
                                    <span
                                        class="middle_text"
                                        >{{ sensor?.objects[0]?.extraText }} 
                                        {{ sensor?.sensorRef?.unit_types[0]?.name }} <div class="sensor_unit" *ngIf="sensor?.sensorRef?.unit_types[0]?.unit != ''">({{ sensor?.sensorRef?.unit_types[0]?.unit }})</div>
                                        <!-- <span class="small_text" style="display: block;">{{ sensor?.device }}</span> -->
                                        </span
                                    >
                                    <!-- <span class="small_text">{{
                                        sensor?.device
                                    }}</span> -->
                                </mat-option>
                            </div>
                        </mat-optgroup>
                    </div>
                </mat-select>
            </mat-form-field>
           <!-- <mat-form-field
                class="col-md-3"
                *ngIf="selectedtype === 'graph'"
            >
                <mat-label>{{ "Color" }}</mat-label>
                <input
                    matInput
                    [(colorPicker)]="color"
                    [value]="color"
                    [style.color]="color"
                />
            </mat-form-field> -->
            <!-- <mat-form-field
                class="col-md-4"
                *ngIf="selectedtype === 'image'"
            >
                <mat-label>{{ "CSS" }}</mat-label>
                <textarea
                    matInput
                    [value]="css"
                    (blur)="changeCss($event)"
                ></textarea>
            </mat-form-field> -->
            <!--<em
                class="fa fa-plus f-20 ter-btn-icon ter-btn-icon-success"
                (click)="addItem()"
            ></em>-->
        </form>
    </ng-container>
    <ng-container *ngIf="selectedtype === 'sensor_value'">
        <form class="form-group row bgc" [formGroup]="sensorForm" *ngIf="itemList && itemList.length < 1">
            <mat-form-field
                class="col-md-5"
            >
                <mat-label>{{ "widget.selectSensor" | translate }}</mat-label>

                <mat-select required formControlName="sensorId" (selectionChange)="onOptionSelected($event, 1)">
                    <mat-form-field
                        [floatLabel]="'never'"
                        appearance="fill"
                        class="w-100 sen_list"
                    >
                        <input matInput #farmFilter placeholder="Search" />
                    </mat-form-field>
                    <div class="scroll_wrap">
                        <mat-option style="height: 0!important;"></mat-option>
                        <mat-optgroup
                            *ngFor="
                                let group of sensorGroup
                                    | filter: farmFilter.value
                            "
                            [label]="group?.device?.nameT ? getTranslation(group?.device?.nameT) : (group?.device.name || '') + ' ' + '(' +group?.device?.id + ')'"

                        >
                            <div class="slct">
                                <mat-option
                                    *ngFor="
                                        let sensor of group.data
                                            | filter: farmFilter.value
                                    "
                                    [value]="sensor.id"
                                    style="text-transform: capitalize"
                                >
                                    <span class="small_text"
                                        >{{ sensor?.id }}.</span
                                    >
                                    <span
                                        class="middle_text"
                                        >{{ sensor?.objects[0]?.extraText }} 
                                        {{ sensor?.sensorRef?.unit_types[0]?.name }} <div class="sensor_unit" *ngIf="sensor?.sensorRef?.unit_types[0]?.unit != ''">({{ sensor?.sensorRef?.unit_types[0]?.unit }})</div>
                                        <!-- <span class="small_text" style="display: block; width: auto;">{{ sensor?.device }}</span> -->
                                        </span
                                    >
                                    <!-- <span class="small_text">{{
                                        sensor?.device
                                    }}</span> -->
                                </mat-option>
                            </div>
                        </mat-optgroup>
                    </div>
                </mat-select>
            </mat-form-field>
            <!-- <mat-form-field
                class="col-md-4"
                *ngIf="selectedtype === 'sensor_value'"
            >
                <mat-label>{{ "CSS" }}</mat-label>
                <textarea
                    matInput
                    [value]="sensorCss"
                    (blur)="changeSensorCss($event)"
                ></textarea>
            </mat-form-field>  <div class="col-md-4 config">
                <button class="btn config_btn" (click)="openConfig()">{{ 'widget.config' | translate }}</button>
            </div> 
            <em
                class="fa fa-plus f-20 ter-btn-icon ter-btn-icon-success"
                (click)="addItem()"
            ></em>-->
        </form>
    </ng-container>
    <ng-container *ngIf="selectedtype === 'soil'" formGroupName="soil">
        <div class="d-flex add-edit-widget-card">
            <div class="card" formGroupName="level30">
                <div class="card-header add-edit-header p-0 d-flex">
                    <div
                        class="add-edit-heading m-0 col-md-6 d-flex align-items-center pl-4"
                    >
                        {{"widget.level" | translate}}
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="w-100 text-cm">
                            <input
                                matInput
                                #widgetFilter
                                maxlength="6"
                                value="30"
                                readonly
                            />
                        </mat-form-field>
                    </div>
                </div>
                <ul class="list-group list-group-flush add-edit-list">
                    <li class="list-group-item">
                        <div
                            class="form-group row"
                            *ngFor="let sensorType of sensorsEnum"
                        >
                            <mat-form-field class="col-12">
                                <mat-label class="col-form-label"
                                    >{{"widget.select" | translate}} {{ sensorType.name }}</mat-label
                                >
                                <mat-select [formControlName]="sensorType.id">
                                    <mat-option
                                        *ngFor="
                                            let sensor of sensors
                                                | unit: sensorType.id
                                        "
                                        [value]="sensor.id"
                                    >
                                        {{ sensor.id }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card add-edit-widgettable" formGroupName="level15">
                <div class="card-header add-edit-header p-0 d-flex">
                    <div
                        class="add-edit-heading m-0 col-md-6 d-flex align-items-center pl-4"
                    >
                        {{"widgets.level" | translate}}
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="w-100 text-cm">
                            <input
                                matInput
                                #widgetFilter
                                maxlength="6"
                                value="15"
                                readonly
                            />
                        </mat-form-field>
                    </div>
                </div>
                <ul class="list-group list-group-flush add-edit-list">
                    <li class="list-group-item">
                        <div
                            class="form-group row"
                            *ngFor="let sensorType of sensorsEnum"
                        >
                            <mat-form-field class="col-12">
                                <mat-label class="col-form-label"
                                    >{{"widgets.select" | translate}} {{ sensorType.name }}</mat-label
                                >
                                <mat-select [formControlName]="sensorType.id">
                                    <mat-option
                                        *ngFor="
                                            let sensor of sensors
                                                | unit: sensorType.id
                                        "
                                        [value]="sensor.id"
                                    >
                                        {{ sensor.id }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card add-edit-widgettable" formGroupName="level10">
                <div class="card-header add-edit-header p-0 d-flex">
                    <div
                        class="add-edit-heading m-0 col-md-6 d-flex align-items-center pl-4"
                    >
                        {{"widgets.level" | translate}}
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="w-100 text-cm">
                            <input
                                matInput
                                #widgetFilter
                                maxlength="6"
                                value="10"
                                readonly
                            />
                        </mat-form-field>
                    </div>
                </div>
                <ul class="list-group list-group-flush add-edit-list">
                    <li class="list-group-item">
                        <div
                            class="form-group row"
                            *ngFor="let sensorType of sensorsEnum"
                        >
                            <mat-form-field class="col-12">
                                <mat-label class="col-form-label"
                                    >{{"widgets.select" | translate}} {{ sensorType.name }}</mat-label
                                >
                                <mat-select [formControlName]="sensorType.id">
                                    <mat-option
                                        *ngFor="
                                            let sensor of sensors
                                                | unit: sensorType.id
                                        "
                                        [value]="sensor.id"
                                    >
                                        {{ sensor.id }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="card add-edit-widgettable" formGroupName="level5">
                <div class="card-header add-edit-header p-0 d-flex">
                    <div
                        class="add-edit-heading m-0 col-md-6 d-flex align-items-center pl-4"
                    >
                        {{"widgets.level" | translate}}
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="w-100 text-cm">
                            <input
                                matInput
                                #widgetFilter
                                maxlength="6"
                                value="5"
                                readonly
                            />
                        </mat-form-field>
                    </div>
                </div>
                <ul class="list-group list-group-flush add-edit-list">
                    <li class="list-group-item">
                        <div
                            class="form-group row"
                            *ngFor="let sensorType of sensorsEnum"
                        >
                            <mat-form-field class="col-12">
                                <mat-label class="col-form-label"
                                    >{{"widgets.select" | translate}} {{ sensorType.name }}</mat-label
                                >
                                <mat-select [formControlName]="sensorType.id">
                                    <mat-option
                                        *ngFor="
                                            let sensor of sensors
                                                | unit: sensorType.id
                                        "
                                        [value]="sensor.id"
                                    >
                                        {{ sensor.id }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="selectedtype === 'devices'">
        <div
            class="form-group row bgc show"
        >
            <mat-form-field
                    class="col-md-5"
                    *ngIf="selectedtype === 'devices'"
                >
                    <mat-label>{{ "Header" | translate }}</mat-label>
                    <mat-select required formControlName="sensorGroupview">
                        <mat-form-field class="w-100" appearance="fill">
                            <input matInput #sensorGroupTypeFilter placeholder="Search" />
                        </mat-form-field>
                        <div class="scroll_bar">
                            <mat-option
                                *ngFor="
                                    let sensorGroupType of sensorGroupTypes
                                        | filter: sensorGroupTypeFilter.value
                                "
                                [value]="sensorGroupType.id"
                            >
                                {{ sensorGroupType?.name | translate }}
                            </mat-option>
                        </div>
                    </mat-select>
            </mat-form-field>

        </div>
    </ng-container>
    <ng-container *ngIf="selectedtype === 'bins' || selectedtype === 'device_info' || selectedtype === 'cropeye' || selectedtype === 'cropeye2'">
        <div 
    class="form-group row bgc show"
>
    <mat-form-field class="col-md-5">
        <mat-label>{{"widget.device" | translate}}</mat-label>
        <mat-select
        formControlName="device"
        (ngModelChange)="changeDevice($event)"
        [alwaysCallback]="true" panelClass="prevent-enter-close" disableOptionCentering="true" (keydown.enter)="$event.stopPropagation()"
        (infiniteScroll)="scrolledDevices()" [complete]="offset === devices.length" msInfiniteScroll
        required>
        <div class="d-flex" style="align-items: center; position: relative;">
        <mat-form-field appearance="fill" class="w-100">
            <input matInput #deviceFilter placeholder="Search" (keydown.enter)="filterDevices(deviceFilter.value); $event.stopPropagation()" type="text"/>
        </mat-form-field>
        <mat-label style="position: absolute; right: 35px; cursor: pointer;" (click)="filterDevices(deviceFilter.value)">
            <i class="fa fa-search f-20"></i>
        </mat-label>
        </div>
        <div class="scroll_wrap" (scroll)="onScrollDevices($event)">
            <mat-option style="height: 0!important;"></mat-option>
            <mat-option
                *ngFor="let device of devices | filter: deviceFilter.value"
                [value]="device.id"
            >
            <div class="names">
                <span class="small_text">{{device?.id}} </span>
                <span *ngIf="device?.name">{{device?.name}} </span>
                <span *ngIf="!device?.name">{{device?.product?.label?.name}}</span>
            </div>
            </mat-option>
        </div>
    </mat-select>
    </mat-form-field>
    </div>
    </ng-container>
    <div
        class="d-flex p-1 align-items-center"
        [ngClass]="{
            'justify-content-between': widget,
            'justify-content-end': !widget
        }"
    >
        <button
            *ngIf="widget"
            [ngStyle]="{ background: navService.headerBackgroundColor }"
            (click)="deleteWidget.emit(widget.id)"
            class="delete"
        >
            <img src="./assets/images/fap/basket.svg" alt="delete relation" />
        </button>
        <div class="action_btns">
            <button
            [ngStyle]="{ background: navService.headerBackgroundColor }"
            class="btn mr-2"
            (click)="submitWidget()"
        >
            {{"translations.save" | translate}}
        </button>
            <button
            [ngStyle]="{ background: navService.headerBackgroundColor }"
            class="btn"
            (click)="cancel.emit()"
        >
            {{"translations.cancel" | translate}}
        </button>
        </div>
    </div>
</form>

<ng-container *cdkPortal>
    <ng-content></ng-content>
  </ng-container>

    <fap-modal #configModal
        [inputPrimaryButtonInterface]="configModalButtonPrimaryInterface"
        [inputSecondaryButtonInterface]="configModalButtonSecondaryInterface"
        inputTitle="Config">
            <form [formGroup]="configForm" class="col-12 p-0">
                <div class="w-100" *ngIf="selectedtype === 'image' || selectedtype === 'sensor_value'">
                    <label class="col-form-labl">Background</label>
                    <mat-form-field (click)="getImages()" class="w-100">
                    <span style="position: absolute; text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 100%;">{{ imgPath }}</span>
                    <div class="form-group w-100 mb-0">
                        <div class="rw">
                            <div class="d-flex w-100">
                                <div class="input-group align-items-baseline w-100 img_bx">
                                            
                                    <mat-select (selectionChange)="addImage($event)" name="image" (infiniteScroll)="scrolledDownImages()" msInfiniteScroll>
                                        <div class="d-flex" style="padding: 0 10px; align-items: center;">
                                        <mat-form-field appearance="fill" class="w-100 disbld">
                                            <input matInput #typeFilter disabled placeholder="Search">
                                        </mat-form-field>
                                        <div class="input-group-append pl-1" (click)="showImageModal()">
                                            <span class="input-group-text"><i
                                                    class="icofont icofont icofont-plus"></i></span>
                                        </div>
                                    </div>
                                    <div class="scroll_wrap" (scroll)="onScrollImages($event)">
                                        <mat-option style="height: 0!important;"></mat-option>
                                        <mat-option style="height: auto!important;" class="noteIcons" *ngFor="let image of images | filter : typeFilter.value" [value]="image">
                                            <div class="name" style="margin: 5px;">
                                                <img style="height: 100px; width: 100%; object-fit: contain" [src]="mediaUrl + image.file" alt="image">
                                            </div>
                                            <div class="actions">
                                                <i class="fa fa-trash" (click)="deleteImage(image?.id); $event.stopPropagation()"></i>
                                            </div>
                                        </mat-option>
                                    </div>
                                    </mat-select>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'Top' }}</mat-label>
                    <input matInput formControlName="top">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'Left' }}</mat-label>
                    <input matInput formControlName="left">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'Font color' }}</mat-label>
                    <input matInput type="color" formControlName="color">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label class="col-form-label">{{ 'Font size' }}</mat-label>
                    <input matInput formControlName="font_size">
                </mat-form-field>
            </div>
            </form>
    </fap-modal>

    <fap-modal #graphConfigModal
        [inputPrimaryButtonInterface]="graphConfigModalButtonPrimaryInterface"
        [inputSecondaryButtonInterface]="graphConfigModalButtonSecondaryInterface"
        inputTitle="Config">
            <form [formGroup]="graphConfigForm" class="col-12 p-0">
                <div class="form-group">
                    <mat-form-field
                        class="w-100"
                    >
                        <mat-label>{{ "widget.graphType" | translate }}</mat-label>
                        <mat-select required formControlName="graphType">
                            <mat-form-field class="w-100" appearance="fill">
                                <input matInput #graphTypeFilter placeholder="Search" />
                            </mat-form-field>
                            <div class="scroll_bar">
                                <mat-option
                                    *ngFor="
                                        let graphType of widgetServices.graphTypes
                                            | filter: graphTypeFilter.value
                                    "
                                    [value]="graphType.id"
                                >
                                    {{ graphType?.name | translate }}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <span class="clr" [ngStyle]="{'background': color}" style="height: 15px; width: 15px; display: inline-block; margin-right: 5px; top: 3px; position: relative;"></span>
                    <mat-form-field style="width: calc(100% - 20px);">
                    <mat-label>{{ "Color" }}</mat-label>
                    <mat-select required formControlName="color" (selectionChange)="changeItemColor($event)">
                        <mat-form-field appearance="fill" class="w-100">
                            <input matInput #graphColorFilter placeholder="Search" />
                        </mat-form-field>
                        <div class="scroll_wrap">
                            <mat-option style="height: 0!important;"></mat-option>
                            <mat-option
                                *ngFor="
                                    let color of graphColors
                                        | filter: graphColorFilter.value
                                "
                                [value]="color.color"
                            >
                            <div class="d-flex w-100 no_bg align-items-center">
                                <span class="clr" [ngStyle]="{'background': color?.color}" style="height: 15px; width: 15px; display: inline-block; margin-right: 10px;"></span> {{ color?.name_t | getTranslation : globalRegistry?.systemData?.translationsMap : langString }}
                            </div>
                            </mat-option>
                        </div>
                    </mat-select>
                </mat-form-field>
                </div>
            </form>
    </fap-modal>

    <fap-modal #addEditTranslationsModal [inputTitle]="label | translate" [showFooter]="false">
        <add-edit-translations 
            [obj]="convertKeysToCamelCase(this.widget)" 
            [prefix]="'WIDGET_'" 
            [translation]="translation"
            [label]="label"
            [group]="'types'"
            (submitTranslation)="onAddUpdateTranslation($event)" 
        >
        </add-edit-translations>
    </fap-modal>

    <fap-modal #addImageModal
        [inputPrimaryButtonInterface]="addImageModalButtonPrimaryInterface"
        [inputSecondaryButtonInterface]="addImageModalButtonSecondaryInterface"
        inputTitle="Add Image">
            <form [formGroup]="imageForm" class="col-12 p-0">
                <div class="form-group" 
                style="display: none">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'Group' }}</mat-label>
                        <input matInput formControlName="group">
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="w-100">
                        <mat-label class="col-form-label">{{ 'Tags' }}</mat-label>
                        <input matInput formControlName="tags" placeholder="Tags">
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <label style="display: block;" class="upload-img-label">{{ 'Image' | translate }}</label>
                    <input #fileUploader type="file" (change)="onFileInput($event)">
                    <img *ngIf="imageSrc" class="preview-img d-block" [src]="imageSrc" alt="image">
                </div>
            </form>
    </fap-modal>