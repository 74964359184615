import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalRegistryService } from "../../../core/global-registry/global-registry.service";
import { DrillParams } from "../../../core/interfaces/widget/drill-info.interface";
import { SensorModel } from "../../../core/models/sensor/sensor.model";
import { WidgetModel } from "../../../core/models/widget/widget.model";
import { ObjectModel } from "../../../core/services/api/objects/object.service";
import { SensorService } from "../../../core/services/api/sensor/sensor.service";
import { UnitTypeModel } from "../../../core/services/api/unit-type/unit-type.service";
import { TableUtil } from "./tableUtil";
import { NavService } from "../../services/nav.service";
import moment from "moment";
import { LocalStorageService } from "../../../core/services/localStorage/local-storage.service";
import { CacheResolverService } from "../../../core/services/api/cache/cache-resolver.service";
import { UserService } from "../../../core/services/api/user/user.service";
import localJson from './mock-data.json'

@Component({
  selector: "fap-table-widget",
  templateUrl: "./fap-table-widget.component.html",
  styleUrls: ["./fap-table-widget.component.scss"],
})
export class FapTableWidgetComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    public globalRegistry: GlobalRegistryService,
    public sensorService: SensorService,
    public navService: NavService,
    private localStorageService: LocalStorageService,
    private cacheService: CacheResolverService,
    private userService: UserService
  ) {}

  @Input() public widget: WidgetModel;
  @Input() objects: ObjectModel[] = [];
  @Input() sensors: SensorModel[] = [];
  @Input() unitTypes: UnitTypeModel[] = [];
  @Input() bgColor = '';

  @Output()
  public emitShow: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public updateWidget: EventEmitter<WidgetModel> = new EventEmitter<WidgetModel>();

  public params: DrillParams;

  public columns: any[] = [];

  public dataSource: any[] = [];
  public finalSensorList = [];
  public subscription: Subscription;
  public sensorIds = [];
  public sensorNames = [];
  public subscription1: Subscription;
  public subscription2: Subscription;
  public subscription3: Subscription;
  public subscription4: Subscription;
  public fromDate = new Date();
  public toDate =  new Date();
  public agg = 'day';
  public trigger = true;
  public toggleMain = false;
  public toggleSub = false;
  public tglShow = false;
  public tableRows = [];
  public tableDates = [];
  public rowNames = [];
  private subscriptions: Array<Subscription> = [];
  public randomId;
  public translatedNames: any = [];
  @Input() public langString: string;
  public noData = false;
  public localSensorData: any = []

  ngOnInit(): void {
    this.randomId = Math.random();
    this.getFromDate();
    this.getToDate();
    this.getAgg();
    this.subscription = this.sensorService.getName.subscribe(res => {
      if(res) {
        this.dataSource = [];
        this.tableRows = [];
        this.drillData();
      }
    });
        this.localStorageService.watchTimezone().subscribe(data => {
          this.drillData();
        })
  }

  getTranslation(translation) {
    const t =this.globalRegistry.systemData.translations.filter(trans => {
      return trans.id === translation
    });
    if(t[0]) {
      if(t[0][this.langString] === null || t[0][this.langString] === '') {
        return translation
      } else {
        return t[0][this.langString];
      }
    } else {
        return translation
      }
  }

  getFromDate() {
    this.subscription1 = this.sensorService.getFromDate.subscribe(res => {
      this.fromDate = res;
    });
  }

  getToDate() {
    this.subscription2 = this.sensorService.getToDate.subscribe(res => {
      this.toDate = res;
    });
  }

  getTrigger() {
    this.subscription4 = this.sensorService.getName.subscribe(res => {
      this.trigger = res;
    });
  }

  getAgg() {
    this.subscription3 = this.sensorService.getAgg.subscribe(res => {
      this.agg = res;
    });
  }

  tglValues() {
    this.columns = [];
    this.tglShow = !this.tglShow;
    this.widget.settings['show_min_max'] = this.tglShow;
    this.updateWidget.emit(this.widget);
    this.drillData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.subscription3.unsubscribe();
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = [];
      this.drillData();
      if(changes.hasOwnProperty('widget') && this.widget) {
        if(this.widget.settings['show_min_max']) {
          this.tglShow = this.widget.settings['show_min_max'];
        }
      }
  }

  exportTable() {
    TableUtil.exportTableToExcel('ExampleMaterialTable'+this.randomId);
  }

  hideMain() {
    this.toggleMain = false
  }

  toggleMenu() {
    this.toggleMain = !this.toggleMain
  }

  getLocalSensors(sensorData: any[]) {
    const objects = Object.values(this.objects);
    const unitTypes = Object.values(this.unitTypes);
    
    return sensorData.map(sensor => {
        const sensorId = sensor.id;
        const sensorObject = this.sensors.find(s => s.id === sensorId);
        if (!sensorObject) return null;
        const sensorUnitTypes = unitTypes.find(unitType => unitType.id === sensorObject.sensorRef.unit_type);
        const sensorObjectData = objects.find(obj => obj.id === sensorObject.sensorRef.object_id);

        return {
            unitType: sensorUnitTypes,
            object: sensorObjectData,
            sensor: sensorObject
        };
    }).filter(Boolean);
  }

  public drillData() {
    this.dataSource = [];
    setTimeout(()=> {
      const sensorIds = this.widget["settings"]["streams"].map(
        (sensor) => sensor?.id
      );
      console.log(sensorIds);
      let url = this.sensorService.getUrl('adv_drill_data/streams='+sensorIds.toString()+'&from='+this.fromDate+'&agg_by='+this.agg+'&to='+this.toDate+'&agg_data=mean,sum,min,max&compare=0');
      this.subscriptions.push(this.sensorService.advdrillData({ streams: sensorIds.toString(), from:this.fromDate,agg_by: this.agg, to:this.toDate, agg_data:'mean,sum,min,max', compare:0}).subscribe(data1 => {
        // const Newdata = localJson.results;
        const Newdata = data1.results;
        this.cacheService.delete(url);
        const data = Newdata['0'];
        console.log(data);
        console.log(this.widget.settings?.['sensors']);
          const arr = Object.keys(data).map(function (key) {
            return { ...data[key], sensor: key, names: '' };
          });
          arr.pop();
          this.localSensorData = this.getLocalSensors(this.widget['settings']['streams']);
          console.log(this.localSensorData);
          const datesData = Object.keys(data).map(function (key) {
            return { [key]: { ...data[key]} };
          });
          const dates = datesData.pop();
          let datesArr = Object.values(dates.labels);
          datesArr = datesArr.reverse();
          const seriesDates = [];
          const tableDates = [];
          let userTimezone = 'UTC';
          this.subscriptions.push(this.userService.getCurrentUser.subscribe((user) => {
            let segments = user.profile.data.timezone.split(',');
            let zone = segments[0];
            let utc = segments[1];
            userTimezone = zone + '( UTC ' + utc + ')';
          }));
          datesArr.forEach((element: any) => {
            const date = new Date(element * 1000);
            seriesDates.push(date.toLocaleString('en-US'));
          });
          seriesDates.forEach(element => {
            if(this.agg == 'hour') {
              element = moment(element).format('DD MMM YYYY, HH') + ' h'
            }
            if(this.agg == 'month') {
              element = moment(element).format('MMM YYYY')
            }
            tableDates.push(element);
          });
         this.tableDates = tableDates;
         
        arr.forEach(element => {
          element.date = this.tableDates;
        });
        this.localSensorData = this.localSensorData.map((item, index) => {
          return {
            ...item,
            data: arr[index]
          };
        });
        console.log(this.localSensorData);
        
        arr.forEach(element => {
          this.localSensorData.forEach(e => {
            if(element.sensor == e.sensor.id) {
              element.name = (e.sensor.name ? e.sensor.name : e.object.extraText ? e.object.extraText : '' + ' ' + e.unitType.name) + ' ' +'<span>' + e.unitType.unit + '</span>' 
            }
          });
        });
        console.log(arr);
        const orderedArr = this.widget.settings?.['sensors'].map(sensor => {
          // Find the matching item in arr where sensor id matches
          return arr.find(item => item.sensor == sensor.id);
        }).filter(Boolean);  // Filter out any undefined entries in case of missing sensors
        
        console.log(orderedArr);
        const names = [];
        orderedArr.forEach(el => {
          names.push(el.name);
        });
        
        this.columns = names;
        this.rowNames = names;
        
        const objectArr = [];
        console.log(this.columns);
        this.columns.forEach(element => {
          const object = {
            colspan: null,
            name: null
          };
          if(this.tglShow) {
            object.colspan = 4;
          } else {
            object.colspan = 1;
          }
          object.name = element;
          objectArr.push(object);
        });
        // this.columns.unshift('');
        const dateObj = {
          colspan: 1,
          name: 'date',
          tooltip: 'Timezone is ' + userTimezone
        }
        objectArr.unshift(dateObj);
        this.columns = objectArr;
        console.log(this.columns);
        const rows = [];
        for (let index = 0; index < orderedArr.length; index++) {
          const data = ['min', 'val', 'max', 'sum'];
          rows.unshift(data);
        }
        rows.unshift('');
        this.tableRows = rows.join(",").split(",");
        console.log(this.tableRows);
        const groupByDate = arr => (
          Object.values(
            arr.reduce(
              (acc, ob) => {
                const { sensor, name, date, aggr_type, min, max, sum } = ob;
                const tgt = ob[aggr_type];
        
                date.forEach((d, i) => {
                  // Append the 'id' to the name to create a unique key
                  const uniqueName = `${name}_${sensor}`;
        
                  acc[d] = {
                    ...(acc[d] || {}),
                    date: d,
                    [uniqueName]: [
                      min[date.length - 1 - i], 
                      tgt ? tgt[date.length - 1 - i] : '-', 
                      max[date.length - 1 - i], 
                      sum[date.length - 1 - i]
                    ]
                  };
                });
        
                return acc;
              },
              {}
            )
          )
        );
        
        
        const result = groupByDate(orderedArr);
        this.dataSource = result;
        // this.dataSource.unshift(this.tableRows);
        console.log(this.dataSource);
        this.dataSource = this.dataSource.map(entry => {
          const orderedEntry: Record<string, any> = { date: entry.date }; // Start with the date property
        
          // Iterate over each sensor ID in the desired order
          this.widget.settings?.['sensors'].forEach(sensor => {
            // Find a key in entry that ends with _sensor.id
            const matchingKey = Object.keys(entry).find(key => key.endsWith(`_${sensor.id}`));
            
            // If a matching key is found, add it to orderedEntry
            if (matchingKey) {
              orderedEntry[matchingKey] = entry[matchingKey];
            }
          });
        
          return orderedEntry;
        });
        
        console.log(this.dataSource);
        
            if(this.dataSource.length === 0) {
            this.noData = true;
          } else {
            this.noData = false;
          }
          const table = document.getElementById('ExampleMaterialTable'+this.randomId);
          const tableBody = document.getElementById("table-body"+this.randomId);
          if(tableBody) tableBody.innerHTML = '';
          if(table && tableBody) {
        if(this.dataSource.length != 0) {
          this.dataSource.forEach((mark) => {
            const tr = document.createElement("tr");
            const { date, ...subs } = mark;
        
            const td = document.createElement("td");
            const aggClassName = this.agg === 'hour' ? 'mw120' : '';
            td.className = `stick ${aggClassName} ${this.bgColor}`;
            td.style.backgroundColor = this.bgColor;
            
            const formattedDate = (this.agg === 'day') ? moment(date).format('DD MMM YYYY') : date;
            td.textContent = formattedDate;
            tr.appendChild(td);
        
            Object.values(subs).forEach(([min, val, max, sum]) => {
                tr.innerHTML += `<td class="minVal">${min || '0'}</td><td>${val || '0'}</td><td class="maxVal">${max || '0'}</td><td class="sumVal">${sum || '0'}</td>`;
            });
        
            tableBody.appendChild(tr);
        });
        
      }
    }
      }));
      this.sensorService.setName(false);
  }, 1000);

  }

  public getLotName(lotId: number = this.widget.objectId): string {
    const lot = this.globalRegistry.systemData.lots.find((lot) => lot.id === lotId);
    return lot ? lot.name : '';
  }
  
}
